<template>
  <video style="width: 100%" ref="stream"></video>
</template>

<script>
export default {
  name: 'Stream',

  props: {
    id: Number,
  },

  mounted() {
    this.$mystore.state.meetingSession.audioVideo.bindVideoElement(
      this.id,
      this.$refs.stream
    )
  },
}
</script>
