<template>
  <MainLayout
    :no-padding="$mystore.state.streams.length > 0"
    :title="title"
    v-on:logout="$emit('logout')"
  >
    <template v-slot:extension v-if="$mystore.state.streams.length > 1">
      <v-tabs v-model="tab" align-with-title slider-color="yellow">
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-for="(streamId, index) in $mystore.state.streams"
          :key="streamId"
        >
          {{ index }}
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-model="tab" v-if="$mystore.state.streams.length > 1">
      <v-tab-item v-for="streamId in $mystore.state.streams" :key="streamId">
        <Stream :id="streamId"></Stream>
      </v-tab-item>
    </v-tabs-items>
    <Stream
      v-else-if="$mystore.state.streams.length === 1"
      :id="$mystore.state.streams[0]"
    ></Stream>
    <p class="mt-5" v-else>
      <b>{{ $t('views.watchStream.noActiveStream') }}</b>
    </p>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import Stream from '@/components/Stream.vue'

export default {
  name: 'WatchStream',

  components: {
    MainLayout,
    Stream,
  },

  data() {
    return {
      title: this.$t('views.watchStream.title'),
      tab: null,
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.watchStream.title')
      document.title = this.title
    },
  },
}
</script>
